// Links
export const HOSPITAL_PROFILE_HELP_LINK =
  "https://repsignal-s2nhealth.zendesk.com/hc/en-us/articles/19328371197083-RepSignal-Hospital-Profile";
export const PHYSICIAN_PROFILE_HELP_LINK =
  "https://repsignal-s2nhealth.zendesk.com/hc/en-us/articles/19328444747419-RepSignal-Physician-Profile";
export const GENERAL_REPSIGNAL_HELP_LINK =
  "https://repsignal-s2nhealth.zendesk.com/hc/en-us";
export const PROCEDURE_MODULE_HELP_LINK =
  "https://repsignal-s2nhealth.zendesk.com/hc/en-us/articles/19310850546331-Procedure-Modules";
export const TERRITORY_PROFILE_HELP_LINK =
  "https://repsignal-s2nhealth.zendesk.com/hc/en-us/articles/20057143016987-RepSignal-Territory-Profile";
export const RECOMMENDED_HOSPITALS_AND_PHYSICIANS_HELP_LINK =
  "https://repsignal-s2nhealth.zendesk.com/hc/en-us/articles/20075118762395-What-are-RepSignal-Recommended-Hospitals-and-RepSignal-Recommended-Physicians-";
export const REGION_PROFILE_HELP_LINK =
  "https://repsignal-s2nhealth.zendesk.com/hc/en-us/articles/20057161375515-RepSignal-Region-Profile";

// Content
export const FINANCIAL_HEALTH_HEALTH_SYSTEM_CONTENT =
  "Data is aggregated from hospitals affiliated with the health system.";
export const FINANCIAL_HEALTH_HOSPITAL_RELATIVE_CONTENT =
  "Performance on the given metric compared to other U.S. hospitals of a similar size.";
export const FINANCIAL_HEALTH_HEALTH_SYSTEM_RELATIVE_CONTENT =
  "Performance on the given metric compared to other U.S. health systems of a similar size.";
export const FINANCIAL_HEALTH_HOSPITAL_OVERALL_SCORE_CONTENT =
  "The Overall Score represents the cumulative financial health of the hospital based upon the various factors outlined below.";
export const FINANCIAL_HEALTH_HEALTH_SYSTEM_OVERALL_SCORE_CONTENT =
  "The Overall Score represents the cumulative financial health of the health system based upon the aggregated data of the hospitals that make up this health system.";
export const FINANCIAL_HEALTH_OPERATING_INCOME_CONTENT =
  "Operating Income = Net Patient Revenue - Total Operating Expense.";
export const FINANCIAL_HEALTH_OPERATING_MARGIN_CONTENT =
  "Operating Margin = Operating Income / Net Patient Revenue.";
export const FINANCIAL_HEALTH_CURRENT_RATIO_CONTENT =
  "Current Ratio = Total Current Assets / Total Current Liabilities";
export const FINANCIAL_HEALTH_DAYS_SALES_OUTSTANDING_CONTENT =
  "Days Sales Outstanding = (Accounts Receivable / Total Revenue) * Total Days in Reporting Period. Days Sales Outstanding measures the average number of days between when a charge or sales is made, and when the revenue is collected or resolved.";
export const PROCEDURE_VOLUME_ESTIMATES_CONTENT =
  "Based on RepSignal's all payor claims, which includes all channels (Medicare, Medicaid, Commercial).";
export const ALL_PAYOR_CLAIMS_CONTENT =
  '"All payor claims" include all channels (Medicare, Medicaid, Commercial).';

// Tooltips/Popovers
export const ALL_PAYOR_CLAIMS_TOOLTIP = {
  link: "https://repsignal-s2nhealth.zendesk.com/hc/en-us/articles/19328242374427-RepSignal-Procedure-Volume-Estimates",
  content: ALL_PAYOR_CLAIMS_CONTENT,
};
export const PROCEDURE_VOLUME_ESTIMATES_TOOLTIP = {
  link: "https://repsignal-s2nhealth.zendesk.com/hc/en-us/articles/19328242374427-RepSignal-Procedure-Volume-Estimates",
  content: PROCEDURE_VOLUME_ESTIMATES_CONTENT,
};
export const INDUSTRY_PAYMENTS_TOOLTIP = {
  link: "https://repsignal-s2nhealth.zendesk.com/hc/en-us/articles/20070433938971-What-are-Industry-Payments-",
  content: "Source: Open Payments. For more information read here",
};
export const ORGANIZATION_PROFILE_TOOLTIP = {
  link: "https://repsignal-s2nhealth.zendesk.com/hc/en-us/articles/19926878200347-RepSignal-Organization-Profile",
  content: "Source: CMS Open Payments",
};
export const AHA_TOOLTIP = {
  link: HOSPITAL_PROFILE_HELP_LINK,
  content: "Source: American Hospital Association Annual Survey.",
};
