<template>
  <!-- Main Modal -->
  <BaseModal
    size="xl"
    :title="subject ? `NOTES ABOUT ${subject}` : 'INTEL BOOK'"
    :modalId="id"
  >
    <template #body>
      <div class="card shadow-sm mb-4">
        <div class="card-body">
          <form
            :id="id + 'NewNoteForm'"
            class="d-flex align-items-center needs-validation"
            @submit.prevent="createNote"
            novalidate
          >
            <div class="col me-3">
              <label for="createNoteTextarea" class="form-label fw-bold"
                >Add Note</label
              >
              <textarea
                id="createNoteTextArea"
                class="form-control"
                v-model="noteContent"
                placeholder="What are your thoughts?"
                rows="3"
                required
              ></textarea>
              <div class="invalid-feedback">Your note cannot be blank.</div>
              <ShareBar
                v-model:subscribers="subscribers"
                :contacts="contacts"
                placeholder="Share your note with..."
                class="mt-3"
              />
            </div>
            <button type="submit" class="btn btn-info fw-bold text-white">
              Post
            </button>
          </form>
        </div>
      </div>
      <!-- Notes -->
      <NotesFilter
        v-show="user.notes.length !== 0"
        v-model:filteredNotes="filteredNotes"
        v-bind="
          type === 'physician' ? { npi } : type === 'facility' ? { ccn } : {}
        "
        class="mb-3"
      />
      <div v-if="filteredNotes.length === 0" class="text-center text-muted">
        No notes found. Get the ball rolling!
      </div>
      <TransitionGroup name="list">
        <NoteCard
          v-for="note in filteredNotes"
          :key="note.id"
          :note="note"
          :contacts="contacts"
          :deleteModal="id + 'ConfirmDelete'"
          class="mb-3"
          @link-clicked="modal.hide()"
          @delete-note="noteToDelete = note"
        />
      </TransitionGroup>
    </template>
  </BaseModal>
  <!-- Confirm Delete Modal -->
  <BaseModal
    title="Delete Note"
    confirmBtnClass="btn-danger"
    confirmBtnText="Delete"
    :modalId="id + 'ConfirmDelete'"
    :closeOnConfirm="true"
  >
    <template #body>
      <h6 class="modal-title fw-bold">
        Are you sure you want to delete this note?
      </h6>
      <p class="text-muted ps-3 border-start mt-3">
        {{ noteToDelete.content }}
      </p>
    </template>
    <template #footer>
      <BaseModalButton
        class="btn btn-danger fw-bold"
        data-bs-dismiss="modal"
        :modalId="id"
        @click="deleteNote"
      >
        Delete
      </BaseModalButton>
      <BaseModalButton
        class="btn btn-light fw-bold"
        data-bs-dismiss="modal"
        :modalId="id"
      >
        Cancel
      </BaseModalButton>
    </template>
  </BaseModal>
</template>

<script>
import { Modal } from "bootstrap";
import { useLoggedInUserStore } from "@/store/loggedInUser";
import NoteCard from "./NoteCard.vue";
import ShareBar from "@/components/layout/ShareBar.vue";
import NotesFilter from "./NotesFilter.vue";
import BaseModal from "@/components/ui/BaseModal.vue";
import BaseModalButton from "@/components/buttons/BaseModalButton.vue";
import { getUserContacts } from "@/services/apiCalls/user";

export default {
  name: "NotesModal",
  components: {
    NoteCard,
    ShareBar,
    NotesFilter,
    BaseModal,
    BaseModalButton,
  },
  props: {
    id: {
      type: String,
      default: "notesModal",
    },
    npi: String,
    ccn: String,
    subject: String,
  },
  setup() {
    const user = useLoggedInUserStore();
    return { user };
  },
  data() {
    return {
      modal: null,
      type: this.npi ? "physician" : this.ccn ? "facility" : "general",
      noteContent: "",
      contacts: [],
      subscribers: [],
      filteredNotes: [],
      noteToDelete: {},
    };
  },
  methods: {
    async getContacts() {
      this.contacts = await getUserContacts();
    },
    async createNote() {
      if (!this.noteContent) {
        return;
      }

      const type = this.type.charAt(0).toUpperCase() + this.type.slice(1); // capitalize first letter
      const key = this.npi ? this.npi : this.ccn ? this.ccn : "";
      const success = await this.user.createNote(
        type,
        this.noteContent,
        this.subscribers,
        key
      );

      if (success) {
        this.noteContent = "";
        var form = document.querySelector(`#${this.id}NewNoteForm`);
        form.classList.remove("was-validated");
      }
    },
    async deleteNote() {
      // wait so user can see delete animation
      setTimeout(() => {
        this.user.deleteNote(this.noteToDelete);
      }, 500);
    },
  },
  created() {
    this.getContacts();
  },
  mounted() {
    // Bootstrap form validation
    const form = document.querySelector(`#${this.id}NewNoteForm`);
    form.addEventListener(
      "submit",
      function (event) {
        if (!form.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add("was-validated");
      },
      false
    );
    this.modal = new Modal(document.getElementById(this.id));
  },
};
</script>

<style scoped>
.modal-header {
  background-color: var(--S2NOceanBlue);
}

textarea {
  resize: none;
}

.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}
</style>
