<template>
  <router-link
    :to="{
      name: routerName,
      params: routerParam,
    }"
    @click="handleNavigate"
    class="d-flex align-items-center nunito"
    :style="{ 'pointer-events': 'auto', color: 'inherit !important' }"
  >
    <img
      v-if="item.type === 'ASC'"
      src="@/assets/asc.svg"
      class="me-2"
      alt="ASC Search Icon"
      style="height: 35px"
    />
    <img
      v-else-if="item.type === 'Office'"
      src="@/assets/office.svg"
      class="me-2"
      alt="Office Search Icon"
      style="height: 35px"
    />
    <img
      v-else-if="item.isHospital"
      src="@/assets/hospitalSearchIcon.svg"
      class="me-2"
      alt="Hospital Search Icon"
      style="height: 35px"
    />
    <img
      v-else
      src="@/assets/facilityIcon.svg"
      class="me-2"
      alt="Facility Search Icon"
      style="height: 35px"
    />
    <div>
      <div class="item-name">
        {{ item.name }}
      </div>
      <div class="d-flex align-items-center text">
        <div v-if="address" class="d-flex align-items-center">
          <i class="pi pi-map-marker me-1" style="font-size: 1rem" />
          <span class="me-3">{{ this.address }} </span>
        </div>
        {{ this.item.displayType }}
      </div>
    </div>
  </router-link>
</template>

<script>
import { formatTitleCase } from "@/services/textFormatters";
import { profileSearch } from "@/services/apiCalls/user";

export default {
  name: "FacilityTile",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleNavigate() {
      if (this.item.isHealthSystem) {
        profileSearch(this.item.id, "health_system");
      } else if (this.item.isHospital) {
        profileSearch(this.item.ccn, "hospital");
      } else if (this.item.isFacility) {
        profileSearch(this.item.npi, "facility");
      } else if (this.item.isOrganization) {
        profileSearch(this.item.org_id, "organization");
      }
    },
  },
  computed: {
    routerName() {
      if (this.item.isHospital) {
        return "hospital";
      } else if (this.item.isHealthSystem) {
        return "health_system";
      } else if (this.item.isOrganization) {
        return "organization";
      }
      return "facility";
    },
    routerParam() {
      if (this.item.isHospital) {
        return { ccn: this.item.ccn };
      } else if (this.item.isHealthSystem) {
        return { id: this.item.id };
      } else if (this.item.isOrganization) {
        return { org_id: this.item.org_id };
      }
      return { npi: this.item.npi };
    },
    address() {
      if (this.item.city && this.item.state) {
        const city = formatTitleCase(this.item.city);
        return `${city}, ${this.item.state}`;
      }
      return "";
    },
  },
};
</script>

<style scoped>
.item-name {
  color: var(--S2NOceanBlue) !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.text {
  color: var(--S2NMedGrey);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.nunito {
  font-family: nunito-sans;
}
</style>
