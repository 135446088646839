<template>
  <div class="flex-grow-1 position-relative">
    <InputGroup>
      <Dropdown
        v-if="!isMobileDevice"
        v-model="selectedType"
        :options="typeOptions"
        panelClass="z-index-2000"
        :panelStyle="{ marginTop: '5px' }"
        style="max-width: 160px"
        scrollHeight="550px"
        optionLabel="label"
        :pt="{
          list: {
            style:
              'margin-bottom: 0px; padding: 0px; font-family: nunito-sans;',
          },
          input: { style: 'font-family: nunito-sans;' },
        }"
      />
      <InputGroupAddon class="addon">
        <i class="pi pi-search"></i>
      </InputGroupAddon>
      <AutoComplete
        v-model="searchTerm"
        :suggestions="items"
        @complete="search"
        :panelStyle="{ marginTop: '5px' }"
        panelClass="z-index-2000"
        optionLabel="name"
        optionGroupLabel="label"
        optionGroupChildren="items"
        completeOnFocus
        style="min-width: 230px"
        @keydown.enter="onEnterPress"
        :focusOnHover="false"
        :autoOptionFocus="false"
        scrollHeight="550px"
        :pt="{
          list: {
            style: 'margin-bottom: 0px;',
          },
          itemGroup: { style: 'padding: 8px 16px' },
          item: { style: 'pointer-events: none;' },
          input: {
            style: 'outline: none; box-shadow: none; font-family: nunito-sans;',
          },
        }"
        :placeholder="selectedType.placeholder"
      >
        <template #header>
          <div class="header montserrat px-3 py-2">
            <span v-if="searchTerm.length">Results for "{{ searchTerm }}"</span>
            <span v-else>Search</span>
          </div>
        </template>
        <template #empty>
          <div class="px-3 py-2 montserrat">
            No results found for the current search term.
          </div>
        </template>
        <!-- Group Headers (Physician / Hospitals & Facilities / Recently Viewed / Frequently Searched) -->
        <template #optiongroup="slotProps">
          <div class="header montserrat">{{ slotProps.option.label }}</div>
        </template>
        <!-- The Item -->
        <template #option="slotProps">
          <div
            class="d-flex justify-content-center"
            v-if="slotProps.option.isLoading"
          >
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div class="nunito" v-else-if="slotProps.option.noRecentViews">
            Profiles will show up here as you continue to view profiles on
            RepSignal!
          </div>
          <div v-else-if="slotProps.option.noFrequentSearches">
            Profiles will show up here as you continue to search profiles on
            RepSignal!
          </div>
          <PhysicianTile
            v-else-if="slotProps.option.item_type === 'physician'"
            :physician="slotProps.option"
          />
          <FacilityTile class="nunito" v-else :item="slotProps.option" />
        </template>
        <template v-if="searchTerm && !isLoading" #footer>
          <router-link
            :to="{
              name: 'universalSearch',
              params: {
                search_term: this.searchTerm,
                search_type: this.selectedType.value,
              },
            }"
            class="px-3 py-2 d-inline-block"
            >See more</router-link
          >
        </template>
      </AutoComplete>
    </InputGroup>
    <div
      v-if="searchTerm && searchTerm.length < 6"
      class="position-absolute tap-to-search z-index-2000"
    >
      Tap
      <div class="enter d-inline-block">Enter</div>
      to search
    </div>
  </div>
</template>

<script>
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import AutoComplete from "primevue/autocomplete";
import Dropdown from "primevue/dropdown";
import PhysicianTile from "./PhysicianTile.vue";
import FacilityTile from "./FacilityTile.vue";
import {
  formatTitleCase,
  formatFacilityType,
  formatHospitalName,
} from "@/services/textFormatters";
import createHttp from "@/services/http";
import isMobile from "@/composables/datatable/isMobile";
import { updateTypeIfOffice, updateTypesIfOffice } from "@/services/utils";

const DISPLAY_TYPES = {
  physician: "Physician",
  hospital: "Hospital",
  health_system: "Health System",
  organization: "Organization",
  // Facilities are covered by `formatFacilityType()`
};

export default {
  name: "UniversalSearchBar",
  components: {
    InputGroup,
    InputGroupAddon,
    AutoComplete,
    Dropdown,
    PhysicianTile,
    FacilityTile,
  },
  setup() {
    const { isMobileDevice } = isMobile("sm");
    return { isMobileDevice };
  },
  data() {
    return {
      searchTerm: "",
      isLoading: false,
      currentRequestId: 0,
      items: [],
      selectedType: {
        label: "All types",
        value: "all",
        header: "Hospitals & Facilities",
        placeholder: "Search a physician or facility",
      },
      physicians: [],
      hospitals: [],
      healthSystems: [],
      organizations: [],
      ascs: [],
      offices: [],
      typeOptions: [
        {
          label: "All types",
          value: "all",
          header: "Hospitals & Facilities",
          placeholder: "Search a physician or facility",
        },
        {
          label: "Physician",
          value: "physician",
          header: "Physicians",
          placeholder: "Search a physician",
        },
        {
          label: "Hospital",
          value: "hospital",
          hospitalFacilityHeader: "Hospitals",
          placeholder: "Search a hospital",
        },
        {
          label: "Health System",
          value: "health_system",
          hospitalFacilityHeader: "Health Systems",
          placeholder: "Search a health system",
        },
        {
          label: "Organization",
          value: "organization",
          hospitalFacilityHeader: "Organizations",
          placeholder: "Search an organization",
        },
        {
          label: "ASC",
          value: "ASC",
          hospitalFacilityHeader: "ASCs",
          placeholder: "Search an ASC",
        },
        {
          label: "Office",
          value: "Office",
          hospitalFacilityHeader: "Offices",
          placeholder: "Search an office",
        },
      ],
    };
  },
  computed: {
    showPhysicians() {
      return (
        (this.selectedType.value === "all") |
        (this.selectedType.value === "physician")
      );
    },
    showHospitalsAndFacilities() {
      return this.selectedType.value !== "physician";
    },
    selectedHospitalsAndFacilities() {
      switch (this.selectedType.value) {
        case "hospital":
          return this.hospitals;
        case "health_system":
          return this.healthSystems;
        case "organization":
          return this.organizations;
        case "ASC":
          return this.ascs;
        case "Office":
          return this.offices;
        default: // 'all' or 'physician'
          return [];
      }
    },
  },
  methods: {
    goToProfile() {
      const item = this.searchTerm;
      // clear searchTerm
      this.searchTerm = "";
      let name, params;
      if (item.item_type == "physician") {
        name = "physician";
        params = { npi: item.npi };
      } else if (item.isHospital) {
        name = "hospital";
        params = { ccn: item.ccn };
      } else if (item.isHealthSystem) {
        name = "health_system";
        params = { id: item.id };
      } else if (item.isOrganization) {
        name = "organization";
        params = { org_id: item.org_id };
      } else {
        name = "facility";
        params = { npi: item.npi };
      }

      this.$router.push({
        name: name,
        params: params,
      });
    },
    onEnterPress() {
      // Handles pressing enter when selecting an item
      if (typeof this.searchTerm === "object") {
        this.goToProfile();
        return;
      }
      this.$router.push({
        name: "universalSearch",
        params: {
          search_term: this.searchTerm,
          search_type: this.selectedType.value,
        },
      });
      // clear searchTerm
      this.searchTerm = "";
    },
    async search() {
      if (typeof this.searchTerm === "object") {
        // When selecting a search result, AutoComplete sets searchTerm to the selected object instead of a string.
        this.searchTerm = this.searchTerm.name ?? "";
      }

      // Storing this separately ensures that all requests in the Promise.all
      // below have the same query value even if `this.searchTerm` changes
      const searchTerm = this.searchTerm?.trim() ?? "";

      // If no search term, handle recent views and frequent searches
      if (!searchTerm) {
        let items = [];
        const recentlyViewed = await this.httpRequest(
          "user/profile_views?count=3"
        );
        if (recentlyViewed.length) {
          const formattedRecentlyViewed = recentlyViewed.map((profile, index) =>
            this.formatSuggestion(profile, index)
          );

          items = items.concat({
            label: "Recently Viewed",
            items: formattedRecentlyViewed,
          });
        } else {
          // No recently viewed profiles
          items = items.concat({
            label: "Recently Viewed",
            items: [{ noRecentViews: true }],
          });
        }

        const frequentlySearched = await this.httpRequest(
          "user/frequent_profile_searches?count=3"
        );
        if (frequentlySearched.length) {
          const formattedfrequentlySearched = frequentlySearched.map(
            (profile, index) => this.formatSuggestion(profile, index)
          );
          items = items.concat({
            label: "Frequently Searched",
            items: formattedfrequentlySearched,
          });
        } else {
          // No frequently searched profiles
          items = items.concat({
            label: "Frequently Searched",
            items: [{ noFrequentSearches: true }],
          });
        }
        this.items = items;
        return;
      }

      this.items = [
        {
          items: [{ isLoading: true }],
        },
      ];
      this.isLoading = true;
      // Since this method is called with a delay (300ms) while typing,
      // need to use requestId to track the final search request
      this.currentRequestId += 1;
      const requestId = this.currentRequestId;

      await Promise.all([
        (this.physicians = await this.quickSearchRequest(
          searchTerm,
          "physician"
        )),
        (this.hospitals = await this.quickSearchRequest(
          searchTerm,
          "hospital"
        )),
        (this.healthSystems = await this.quickSearchRequest(
          searchTerm,
          "health_system"
        )),
        (this.organizations = await this.quickSearchRequest(
          searchTerm,
          "organization"
        )),
        (this.ascs = await this.quickSearchRequest(
          searchTerm,
          "facility",
          "ASC"
        )),
        (this.offices = await this.quickSearchRequest(
          searchTerm,
          "facility",
          "Office"
        )),
      ]);
      let items = [];

      if (this.showPhysicians) {
        const physicians = this.firstThreePhysicians();
        if (physicians.length) {
          items = items.concat({
            label: "Physicians",
            items: physicians,
          });
        }
      }
      if (this.showHospitalsAndFacilities) {
        const hospitalsAndFacilities = this.firstThreeHospitalsAndFacilities();
        const label =
          this.selectedType.value === "all"
            ? "Hospitals & Facilities"
            : this.selectedType.hospitalFacilityHeader;
        if (hospitalsAndFacilities.length) {
          items = items.concat({
            label: label,
            items: hospitalsAndFacilities,
          });
        }
      }
      // Only set items if request ids match
      if (requestId === this.currentRequestId) {
        this.items = items;
        this.isLoading = false;
      }
    },
    async quickSearchRequest(name, type, subtype) {
      let endpoint = `${type}/quick_search?`;

      const searchParams = new URLSearchParams({ max_results: 3 });

      const words = name.split(" ");
      if (type === "physician" && words.length === 2) {
        searchParams.set("first_name", words[0]);
        searchParams.set("last_name", words[1]);
      } else {
        searchParams.set("name", name);
      }

      if (subtype) {
        searchParams.set("type", subtype);
      }

      endpoint += searchParams.toString();

      try {
        const results =
          type === "facility"
            ? updateTypesIfOffice(await this.httpRequest(endpoint))
            : await this.httpRequest(endpoint);
        return results.map((item) => ({
          ...item,
          displayType:
            type === "facility"
              ? formatFacilityType(item.type)
              : DISPLAY_TYPES[type] ?? type,
          isHospital: type === "hospital",
          isFacility: type === "facility",
          isHealthSystem: type === "health_system",
          isOrganization: type === "organization",
        }));
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    async httpRequest(endpoint) {
      const response = await createHttp().get(
        `${import.meta.env.VITE_FLASK_URL}/api/${endpoint}`
      );
      return response.data;
    },
    formatSuggestion(profile, index) {
      let name = profile.name;
      if (profile.profile_type === "physician") {
        name = formatTitleCase(name);
      } else if (name.toUpperCase() === name) {
        name = formatHospitalName(name);
      }

      updateTypeIfOffice(profile);

      const displayType =
        profile.profile_type === "facility"
          ? formatFacilityType(profile.type)
          : DISPLAY_TYPES[profile.profile_type] ?? profile.profile_type;

      return {
        ...profile,
        displayType,
        name,
        index,
        isPhysician: profile.profile_type === "physician",
        isHospital: profile.profile_type === "hospital",
        isFacility: profile.profile_type === "facility",
        isHealthSystem: profile.profile_type === "health_system",
        isOrganization: profile.profile_type === "organization",
        location: this.formatLocation(profile.city, profile.state),
        primary_specialty:
          profile.profile_type === "physician"
            ? formatTitleCase(profile.primary_specialty)
            : undefined,
      };
    },
    firstThreePhysicians() {
      return this.physicians.slice(0, 3).map((physician) => ({
        ...physician,
        name: formatTitleCase(physician.name),
        primary_specialty: formatTitleCase(physician.primary_specialty),
        location: this.formatLocation(physician.city, physician.state),
      }));
    },
    firstThreeHospitalsAndFacilities() {
      const maxItems = 3;
      if (this.selectedType.value !== "all") {
        return this.selectedHospitalsAndFacilities
          .slice(0, maxItems)
          .map((item) => this.formatHospitalOrFacility(item));
      }

      const facilities = [...this.ascs, ...this.offices].sort(
        (a, b) => a.npi - b.npi
      );
      // So that it isn't almost always an ASC first, for type variety

      const orgsAndHealthSystems = [
        ...this.organizations,
        ...this.healthSystems,
      ];

      const prioritizedItems = [];
      const maxIndex = Math.max(
        this.hospitals.length,
        facilities.length,
        orgsAndHealthSystems.length
      );

      // iterate through the 3 "groups" (hospitals, facilities, orgs/health
      // systems) round-robin style to ensure that at least 1 of each is
      // included in result if possible
      for (let i = 0; i < maxIndex; i++) {
        if (i < this.hospitals.length) {
          prioritizedItems.push(this.hospitals[i]);
        }

        if (i < facilities.length) {
          prioritizedItems.push(facilities[i]);
        }

        if (i < orgsAndHealthSystems.length) {
          prioritizedItems.push(orgsAndHealthSystems[i]);
        }

        if (prioritizedItems.length >= maxItems) {
          break;
        }
      }

      return this.sortByName(prioritizedItems.slice(0, maxItems)).map((item) =>
        this.formatHospitalOrFacility(item)
      );
    },
    formatHospitalOrFacility(item) {
      return {
        ...item,
        name:
          // Convert name to Title Case if it's ALL CAPS.
          // As of writing this applies to facilities & orgs.
          item.name === item.name.toUpperCase()
            ? formatHospitalName(item.name)
            : item.name,
        location: this.formatLocation(item.city, item.state),
      };
    },
    formatLocation(city, state) {
      if (city && state) {
        return `${formatTitleCase(city)}, ${state}`;
      } else if (city) {
        return formatTitleCase(city);
      } else if (state) {
        return state;
      }
      return null;
    },
    sortByName(arr) {
      return arr.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
    },
  },
};
</script>

<style scoped>
.header {
  color: var(--S2NDarkGrey);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.addon {
  background-color: var(--vt-c-white);
}

.tap-to-search {
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--S2NMedGrey);
}

.enter {
  border: 0.5px solid var(--S2NMedGrey);
  border-radius: 8px;
  padding: 2px 8px;
}

.montserrat {
  font-family: montserrat;
}

.nunito {
  font-family: nunito-sans;
}

/* Override primevue autocomplete component */
:deep(.p-autocomplete-input) {
  border-left: none;
}
:deep(.p-autocomplete-input:focus),
:deep(.p-autocomplete-input:hover) {
  border: 1px solid #2196f3;
}
</style>
