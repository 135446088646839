<template>
  <Popper
    offsetDistance="12"
    class="custom-popper"
    :arrow="true"
    :show="showPopover"
    :placement="placement"
  >
    <div>
      <div :class="{ backdrop: showPopover }"></div>
      <div class="p-relative">
        <div :class="{ anchor: showPopover }" :style="anchorStyle">
          <slot name="anchor"></slot>
          <div :class="{ 'invisible-overlay': showPopover }"></div>
        </div>
      </div>
    </div>

    <template #content>
      <div>
        <h5 class="fw-bold popover-header">{{ header }}</h5>
        <div class="my-3 popover-content">
          <slot name="content"></slot>
        </div>
        <div class="popover-footer">
          <slot name="footer" :dismiss="dismissTutorial">
            <div class="d-flex justify-content-end">
              <a v-if="link" class="me-2" :href="link" target="_blank">
                <button class="btn ocean-blue-button-inverse">
                  Learn More
                </button>
              </a>
              <button
                class="btn ocean-blue-button-inverse"
                @click="dismissTutorial"
                data-test="dismissButton"
              >
                Looks Good!
              </button>
            </div>
          </slot>
        </div>
      </div>
    </template>
  </Popper>
</template>

<script>
import { useLoggedInUserStore } from "@/store/loggedInUser";
import Popper from "vue3-popper";

export default {
  name: "TutorialPopover",
  components: {
    Popper,
  },
  emits: ["dismiss"],
  setup() {
    const user = useLoggedInUserStore();
    return { user };
  },
  props: {
    feature: {
      type: String,
      required: true,
    },
    header: {
      type: String,
    },
    link: {
      type: String,
    },
    placement: {
      type: String,
      default: "bottom",
    },
    /**
     * If `false`, always hide the popover.
     * If `true`, only show the popover if has never been dismissed.
     * @type {Boolean}
     */
    showIfNeverDismissed: {
      type: Boolean,
      default: true,
    },
    anchorStyle: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      wasDismissed: false,
    };
  },
  computed: {
    showPopover() {
      return (
        !this.wasDismissed &&
        this.showIfNeverDismissed &&
        this.user.acceptedEula
      );
    },
  },
  methods: {
    getWasDismissed(feature) {
      return localStorage.getItem(feature) === "true";
    },
    dismissTutorial() {
      this.wasDismissed = true;
      localStorage.setItem(this.feature, true);
      this.$emit("dismiss");
    },
  },
  mounted() {
    this.wasDismissed = this.getWasDismissed(this.feature);
  },
};
</script>

<style scoped>
.custom-popper {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 10px;
  --popper-theme-padding: 35px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(200, 122, 210, 0.25);
}

.popover-header {
  font-size: 1.125rem;
}

.popover-content {
  font-size: 0.875rem;
}

.popover-footer .btn {
  --bs-btn-font-size: 0.875rem;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9000;
}

.anchor {
  position: relative;
  z-index: 9001;
  background-color: white;
  border-radius: 4px;
}

.invisible-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9002;
}
</style>
