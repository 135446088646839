import { defineStore } from "pinia";
import {
  getSignaledPhysicians,
  getProcedureModuleInfo,
} from "@/services/apiCalls/procedure_module";

export const procedureModuleStore = defineStore({
  // id is required so that Pinia can connect the store to the devtools
  id: "procedureModule",
  state: () => ({
    id: null,
    name: "",
    keywords: "",
    twitterHandles: [],
    specialties: [],
    productCodes: [],
    procSets: {},
    cpts: [],
    icd10s: [],
    targetNpis: [],
    diagSets: {},
  }),
  persist: true,
  getters: {},
  actions: {
    async updateSignaledNpis() {
      try {
        const result = await getSignaledPhysicians();
        this.targetNpis = result.target_npis;
      } catch {
        console.log("Could not switch Signaled NPIs");
      }
    },
    async getProcModInfo() {
      const result = await getProcedureModuleInfo();
      this.id = result.id;
      this.name = result.name;
      this.keywords = result.keywords;
      this.twitterHandles = result.twitter_handles;
      this.specialties = result.specialties;
      this.productCodes = result.product_codes;
      this.procSets = result.procedure_sets;
      this.cpts = result.cpts;
      this.icd10s = result.icd10s;
      this.diagSets = result.diagnosis_sets;
    },
    async update() {
      await this.getProcModInfo();
      this.updateSignaledNpis();
    },
  },
});
