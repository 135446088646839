import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView/index.vue";
import { useLoggedInUserStore } from "@/store/loggedInUser";

const authGuard = async (to, from, next) => {
  const user = useLoggedInUserStore();
  await user.checkIfLoggedInAsAdmin();

  if (!user.isAuthenticated()) {
    if (!to.query.temp_token) {
      next({ name: "login", query: { redirect: to.path } });
      return;
    }

    // if temp token provided, try to validate
    const tempToken = to.query.temp_token;
    try {
      await user.validateTempToken(tempToken);
    } catch (invalidTokenError) {
      next({ name: "login", query: { redirect: to.path } });
      return;
    }
  }

  user.logActivity(to.fullPath);

  if (
    user.isRep &
    ((user.territory_id == null) & (user.region_id == null) ||
      user.assigned_empty_region)
  ) {
    // Check if user is setup
    next("/setup-required");
  } else if (!user.acceptedEula) {
    // check if they has not accepted the EULA
    next("/eula");
  } else {
    next();
  }
};

const leaderGuard = (to, from, next) => {
  const user = useLoggedInUserStore();
  if (user.isRep) {
    next("/");
  } else {
    next();
  }
};

const regionGuard = (to, from, next) => {
  const user = useLoggedInUserStore();
  const regionId = to.params.id;
  if (
    regionId &&
    !user.accessibleRegions.some((region) => region.id == regionId)
  ) {
    next({ name: "NotFound" });
  } else {
    next();
  }
};

const territoryGuard = (to, from, next) => {
  const user = useLoggedInUserStore();
  const territoryId = to.params.id;
  if (
    territoryId &&
    !user.accessibleTerritories.some((ter) => ter.id == territoryId)
  ) {
    next({ name: "NotFound" });
  } else {
    next();
  }
};

const adminGuard = (to, from, next) => {
  const user = useLoggedInUserStore();
  if (user.is_admin) {
    next();
  } else {
    next("/");
  }
};

const logout = (to, from, next) => {
  const user = useLoggedInUserStore();
  user.logout();
  next({ name: "login", query: { redirect: from.path } });
};

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
  routes: [
    {
      path: "/rs/",
      name: "home",
      alias: "/",
      component: HomeView,
      beforeEnter: authGuard,
      meta: {
        title: "Home",
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("../views/LoginView.vue"),
      meta: {
        title: "Login",
      },
    },
    {
      path: "/forgot_password",
      name: "forgotPassword",
      component: () => import("../views/ForgotPasswordView.vue"),
      meta: {
        title: "Forgot Password",
      },
    },
    {
      path: "/logout",
      name: "logout",
      beforeEnter: logout,
    },
    {
      path: "/weekly_email_feedback",
      name: "emailFeedback",
      component: () => import("../views/EmailFeedbackView.vue"),
    },
    {
      path: "/explorer",
      name: "explorer",
      component: () => import("../views/ExplorerView/index.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Explorer",
      },
    },
    {
      path: "/explorer/refresh",
      name: "explorerRefresh",
      component: () => import("../views/ExplorerView/Refresh.vue"),
      beforeEnter: [authGuard, adminGuard],
    },
    {
      path: "/market_summary",
      name: "marketSummary",
      component: () => import("../views/MarketSummaryView/index.vue"),
      beforeEnter: [authGuard, leaderGuard],
      meta: {
        title: "MarketSignal",
      },
    },
    {
      path: "/physician/:npi",
      name: "physician",
      component: () => import("../views/PhysicianView/index.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/drug/:name",
      name: "drug",
      component: () => import("../views/DrugView/index.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/procedure/:cpt_code",
      name: "procedure",
      component: () => import("../views/ProcedureView/index.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/regions/:id?",
      name: "regions",
      component: () => import("../views/RegionsView/index.vue"),
      beforeEnter: [authGuard, regionGuard],
    },
    {
      path: "/regions/manager",
      name: "region_manager",
      component: () => import("../views/RegionsView/index.vue"),
      beforeEnter: [authGuard, leaderGuard],
      meta: {
        title: "Territories",
      },
    },
    {
      path: "/territory/:id",
      name: "territory",
      component: () => import("../views/TerritoryView.vue"),
      beforeEnter: [authGuard, territoryGuard],
    },
    {
      path: "/territory",
      name: "territory_search",
      component: () => import("../views/TerritoryView.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/hospital/:ccn/:tab?",
      name: "hospital",
      component: () => import("../views/HospitalView/index.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/organization/:org_id",
      name: "organization",
      component: () => import("../views/OrganizationView/index.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/facility/:npi",
      name: "facility",
      component: () => import("../views/FacilityView/index.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/health_system/:id",
      name: "health_system",
      component: () => import("../views/HealthSystemView/index.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/lists",
      name: "lists",
      component: () => import("../views/ListView.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Lists",
      },
    },
    {
      path: "/lists/:id",
      name: "listDetails",
      component: () => import("../views/ListDetailView/index.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/account",
      name: "account",
      component: () => import("../views/AccountView/index.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "My Account",
      },
    },
    {
      path: "/search",
      name: "search",
      component: () => import("../views/SearchView/index.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Search",
      },
    },
    {
      path: "/search/physicians",
      name: "search_physicians",
      component: () =>
        import("../views/SearchView/SearchPhysiciansView/index.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Search Physicians",
      },
    },
    {
      path: "/search/hospitals",
      name: "search_hospitals",
      component: () =>
        import("../views/SearchView/SearchHospitalView/index.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Search Hospitals",
      },
    },
    {
      path: "/search/facilities",
      name: "search_facilities",
      component: () =>
        import("../views/SearchView/SearchFacilityView/index.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "Search Facilities",
      },
    },
    {
      path: "/search/medschool",
      name: "search_med_school",
      component: () =>
        import("../views/SearchView/SearchMedSchoolView/index.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/territory/optimizer",
      name: "territoryOptimizer",
      component: () => import("../views/TerritoryOptimizerView/index.vue"),
      beforeEnter: [authGuard, leaderGuard],
      meta: {
        title: "Territory Optimizer",
      },
    },
    {
      path: "/organization/builder",
      name: "organizationBuilder",
      component: () => import("../views/OrgBuilderView.vue"),
      beforeEnter: [authGuard, leaderGuard],
    },
    {
      path: "/open_payment",
      name: "openPayments",
      component: () => import("../views/OpenPaymentView/index.vue"),
      beforeEnter: authGuard,
    },
    {
      path: "/newsfeed",
      name: "newsfeed",
      component: () => import("../views/NewsfeedView.vue"),
      beforeEnter: authGuard,
      meta: {
        title: "News",
      },
    },
    {
      path: "/eula",
      name: "eula",
      component: () => import("../views/EulaView.vue"),
    },
    {
      path: "/privacy_policy",
      name: "privacy",
      alias: "/liveness_check",
      component: () => import("../views/PrivacyPolicyView.vue"),
    },
    {
      path: "/password_reset_verified/:token",
      name: "passwordReset",
      component: () => import("../views/PasswordResetView.vue"),
    },
    {
      path: "/setup-required",
      name: "setupRequired",
      component: () => import("../views/SetupRequiredView.vue"),
    },
    {
      path: "/procedure_set/:id",
      name: "procedureSet",
      component: () => import("../views/ProcSetAdminView.vue"),
      beforeEnter: [authGuard, adminGuard],
    },
    {
      path: "/universal_search/:search_type/:search_term",
      name: "universalSearch",
      component: () => import("../views/UniversalSearchView.vue"),
      meta: {
        title: "Search",
      },
    },
    {
      path: "/:catchAll(.*)*",
      name: "NotFound",
      component: () => import("../views/PageNotFound.vue"),
    },
  ],
});

router.beforeEach((to) => {
  document.title = to.meta?.title
    ? `${to.meta.title} - RepSignal`
    : "RepSignal";
});

export default router;
