const BREAK_CHARS = [" ", ",", "/", "-", ":"];

export function formatProcVolume(volume) {
  if (volume === null) return "1-10";
  if (parseInt(volume, 10) === 0) return "*";

  return parseInt(volume) < 11
    ? "1-10"
    : Math.round(parseInt(volume, 10)).toLocaleString();
}

export function formatProcVolumeCsv(volume) {
  const formattedProcVolume = formatProcVolume(volume);
  return formattedProcVolume === "1-10" ? "1--10" : formattedProcVolume;
}

export function capitalizeText(text) {
  if (!text) return "";
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function truncateText(text, max_length) {
  if (!text) return "";
  if (text.length <= max_length) return text;
  return (
    text.slice(0, max_length).split(" ").slice(0, -1).join(" ") + "..."
  ).replace("/", "/ ");
}

export function formatDate(date) {
  if (!date) {
    return date;
  }
  const strDate = date.toLocaleDateString("en-US");
  return date >= new Date("12/31/9999")
    ? "N/A"
    : new Date(strDate.split(" ")[0]).toLocaleDateString("en-US");
}

export function formatDollar(value) {
  if (value === undefined) return "$0.00";
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  }).format(value);
}

export function formatName(name) {
  if (!name) {
    return "";
  }

  const nameArr = name.split(" ");
  const formattedNameArr = nameArr.map((nameStr) => {
    const hyphenSplitStr = nameStr.split("-");
    const formattedStrArr = hyphenSplitStr.map(
      (subStr) => subStr[0] + subStr.slice(1).toLowerCase()
    );
    return formattedStrArr.join("-");
  });
  return formattedNameArr.join(" ");
}

export function formatHospitalNames(hospitals) {
  if (!hospitals) {
    return hospitals;
  }
  return hospitals.map((hosp) => ({
    ...hosp,
    name: formatHospitalName(hosp.name),
  }));
}

export function formatHospitalName(hospName) {
  if (!hospName) {
    return "";
  }
  let editedName = capitalizeFirstLettersOnly(hospName);
  editedName = lowerCasePrepositions(editedName);
  editedName = capitalizeAcronyms(editedName);
  editedName = capitalizeStates(editedName);
  editedName = capitalizeSingleLetters(editedName, true);
  editedName = capitalizeFirstLetter(editedName);
  return editedName;
}

export function formatFacilityType(facilityType) {
  switch (facilityType) {
    case "Hospitals":
      return "Hospital";
    case "Other":
      return "Facility";
    default:
      return facilityType;
  }
}

export function formatTitleCase(text) {
  if (!text) {
    return "";
  }
  let formattedText = capitalizeFirstLettersOnly(text);
  formattedText = lowerCasePrepositions(formattedText);
  formattedText = capitalizeAcronyms(formattedText);
  formattedText = capitalizeSingleLetters(formattedText, false);
  formattedText = capitalizeFirstLetter(formattedText);
  return formattedText;
}

export function capitalizeFirstLettersOnly(text) {
  if (!text) return "";
  const lowerCasedText = text.toLowerCase();
  const regex = /([^']\b[a-z](?!\s))/g;

  return lowerCasedText.replace(regex, function (firstLetter) {
    return firstLetter.toUpperCase();
  });
}

export function lowerCasePrepositions(text) {
  if (!text) return "";
  let formattedText = text.replace(/The /g, "the ");
  formattedText = formattedText.replace(/And /g, "and ");
  formattedText = formattedText.replace(/Of /g, "of ");
  formattedText = formattedText.replace(/At /g, "at ");
  formattedText = formattedText.replace(/As /g, " as ");
  formattedText = formattedText.replace(/For /g, " for ");
  formattedText = formattedText.replace(/Or /g, " or ");
  return formattedText;
}

export function capitalizeAcronyms(text) {
  if (!text) return "";
  return capitalizeSet(text, ACRONYMS);
}

export function capitalizeStates(text) {
  if (!text) return "";
  return capitalizeSet(text, STATES);
}

export function capitalizeSingleLetters(text, includingA = false) {
  if (!text) return "";
  return capitalizeSet(text, SINGLE_LETTERS, includingA);
}

function capitalizeSet(text, set, includingA = false) {
  let formattedString = text;

  BREAK_CHARS.forEach((breakpoint) => {
    let splitString = formattedString.split(breakpoint);
    splitString.forEach((word, index) => {
      if (word === "A" && includingA) {
        splitString[index] = word;
      } else if (set.has(word.toUpperCase())) {
        splitString[index] = word.toUpperCase();
      } else {
        splitString[index] = word;
      }
    });
    formattedString = splitString.join(breakpoint);
  });

  return formattedString;
}

export function capitalizeFirstLetter(text) {
  if (!text) return "";
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function formatPercent(num) {
  if (!num) return "*";
  return new Intl.NumberFormat("default", {
    style: "percent",
    maximumFractionDigits: 0,
  }).format(num);
}

const ACRONYMS = new Set([
  "IHS",
  "DBA",
  "UCH",
  "CHI",
  "UPMC",
  "CHRISTUS",
  "PAM",
  "S.",
  "SSM",
  "U.",
  "AMITA",
  "HSHS",
  "OSF",
  "ARH",
  "J.",
  "NYC",
  "UH",
  "H.",
  "UT",
  "UNC",
  "B.",
  "VA",
  "HCA",
  "AMG",
  "LLC",
  "LLC.",
  "MUSC",
  "C.",
  "E.",
  "W.",
  "KPC",
  "INTEGRIS",
  "A.",
  "DMC",
  "F.",
  "UW",
  "UVA",
  "HIMA",
  "UF",
  "G.",
  "R.",
  "TENET",
  "WVU",
  "JFK",
  "D.",
  "UCLA",
  "UP",
  "USC",
  "I.",
  "PIH",
  "LSU",
  "N.",
  "VCU",
  "M.",
  "CHG",
  "SCL",
  "SUN",
  "L.",
  "DSH",
  "USA",
  "SOVAH",
  "UHS",
  "TMC",
  "UNM",
  "OHSU",
  "ER",
  "UCSF",
  "LECOM",
  "T.",
  "JPS",
  "KALEIDA",
  "CORE",
  "YRMC",
  "NYU",
  "NMC",
  "USMD",
  "UC",
  "CGH",
  "G.V.",
  "P.",
  "AHMC",
  "MT",
  "RMH",
  "RML",
  "OU",
  "DCH",
  "CCC",
  "CCM",
  "OCH",
  "LMH",
  "SUNY",
  "BHC",
  "MT.",
  "PHS",
  "NEA",
  "CHOC",
  "(LTAC)",
  "SE",
  "LDS",
  "UAMS",
  "LA",
  "CA",
  "FHN",
  "LTAC",
  "HSC",
  "DHHS",
  "OSS",
  "TIRR",
  "AR",
  "UCI",
  "AD",
  "W.W.",
  "T.J.",
  "LAC+USC",
  "DC",
  "TOPS",
  "M.D.",
  "RMC",
  "KMI",
  "USD",
  "SEARHC",
  "LTACH",
  "UMC",
  "NCH",
  "REX",
  "USAF",
  "KVC",
  "AHN",
  "BSA",
  "ACMH",
  "LP",
  "INC",
  "D/B/A",
  "BMH",
  "OBL",
  "ASC",
  "IMG",
  "LC",
  "PLLC",
  "MRI",
  "LLP",
]);

const SINGLE_LETTERS = new Set([
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
]);

const STATES = new Set([
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
]);
