<template>
  <div
    :class="['card border border-white h-100', colorClass]"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div class="card-body">
      <div class="card-icon">
        <slot
          name="icon"
          :color="hover ? '#FFFFFF' : 'var(--S2NOceanBlue)'"
        ></slot>
      </div>
      <h2 class="card-title">
        <slot name="heading"></slot>
      </h2>
      <p class="card-text">
        <slot></slot>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    colorClass: String,
  },
  data() {
    return {
      hover: false,
    };
  },
};
</script>

<style scoped>
.card {
  box-shadow: 0px 0px 6px 0px rgba(226, 224, 224, 0.889);
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s;
}
.card:hover {
  background-color: var(--S2NOceanBlue);
  .card-title {
    color: #ffffff;
  }
  .card-text {
    color: #ffffff;
  }
}
.card-body {
  color: black;
  text-align: left;
  margin-bottom: 2.5rem;
}
.card-icon {
  padding-bottom: 0.5rem;
  padding-top: 0.75rem;
}
.card-title {
  color: black;
  font-family: montserrat, sans-serif;
  font-weight: 700;
  font-size: 24px;
}
.card-text {
  height: 3.7rem;
  font-family: Nunito, sans-serif;
  font-weight: 400;
  font-size: 16px;
}
.card-title {
  margin-bottom: 0.75rem;
}
</style>
