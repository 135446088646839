export const AHA_YEAR = 2023;

export function sumProcedureVolumes(volumes) {
  const totalProcedures = volumes.reduce((total, volume) => total + volume, 0);
  const hasSensitiveVolume = volumes.includes(null);

  if (totalProcedures === 0 && hasSensitiveVolume) {
    return null;
  }

  return totalProcedures;
}

export function sumDiagnosisVolumes(volumes) {
  // Algorithm is the same as sumProcedureVolumes
  return sumProcedureVolumes(volumes);
}

/**
 * provides the value used for sorting by volumes
 * @param {*} volume a procedure or diagnosis volume
 * @returns the value used for sorting the volume
 */
export function volumeSortingValue(volume) {
  const volumeIsSensitive = volume === null;
  if (volumeIsSensitive) {
    return 1;
  } else if (volume === "*") {
    return 0;
  } else {
    return volume;
  }
}

/**
 * Returns whether an item is in the given territory.
 * @param item : any item with a lat and lon value
 * @param territory : a territory with a lat, lon, and region_radius
 */
export function isInTerritory(item, territory) {
  const LAT_DIST = 69; // 1 degree of latitude
  const LON_DIST = 54.6; // 1 degree of longitude -- these are approximate

  if (!item || !territory) {
    return false;
  }

  if (
    !item.lat ||
    !item.lon ||
    !territory.lat ||
    !territory.lon ||
    !territory.radius
  ) {
    return false;
  }

  const lat_dist_adj = territory.radius / LAT_DIST;
  const long_dist_adj = territory.radius / LON_DIST;

  return (
    territory.lat - lat_dist_adj <= item.lat &&
    item.lat <= territory.lat + lat_dist_adj &&
    territory.lon - long_dist_adj <= item.lon &&
    item.lon <= territory.lon + long_dist_adj
  );
}

const facilityOfficeTypes = new Set(["OBL", "IMAGING", "Clinic/Practice"]);

/**
 * Updates `facility.type` in-place to "Office" if the current type is
 * "OBL", "IMAGING", or "Clinic/Practice"
 *
 * https://github.com/S2N-Health/bandon/issues/4144
 * @param {{type: string}} facility - The facility object to update
 * @return the modified facility
 */
export function updateTypeIfOffice(facility) {
  if (facilityOfficeTypes.has(facility.type)) {
    facility.type = "Office";
  }
  return facility;
}

/**
 * For each facility in `facilities`, updates the type in-place to "Office" if
 * the current type is "OBL", "IMAGING", or "Clinic/Practice"
 *
 * https://github.com/S2N-Health/bandon/issues/4144
 * @param {Array} facilities - The facilities to update
 * @return the modified list of facilities
 */
export function updateTypesIfOffice(facilities) {
  return facilities.map(updateTypeIfOffice);
}
